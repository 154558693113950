<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-dialog
          v-model="dialog"
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="createMcq"
          >
            Create new MCQ
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">MCQ Details</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="takeAction">
                <validation-observer
                    ref="observer"
                    v-slot="{ invalid }"
                >
                  <v-row>
                    <!--Question-->
                    <v-col
                        cols="12"
                    >
                      <quill-editor
                          :options="{placeholder: 'Enter question'}"
                          v-model="question"
                      ></quill-editor>
                    </v-col>

                    <!--Question Photo-->
                    <v-col
                        cols="12"
                    >
                      <v-file-input
                          chips
                          label="Choose question photo"
                          v-model="question_photo"
                      ></v-file-input>
                    </v-col>

                    <!--A-->
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="A"
                          rules="required"
                      >
                        <v-text-field
                            v-model="a"
                            :error-messages="errors"
                            label="Option A"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <!--B-->
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="B"
                          rules="required"
                      >
                        <v-text-field
                            v-model="b"
                            :error-messages="errors"
                            label="Option B"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <!--C-->
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="C"
                          rules="required"
                      >
                        <v-text-field
                            v-model="c"
                            :error-messages="errors"
                            label="Option C"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <!--D-->
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="D"
                          rules="required"
                      >
                        <v-text-field
                            v-model="d"
                            :error-messages="errors"
                            label="Option D"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <!--Answer-->
                    <v-col
                        class="d-flex"
                        cols="12"
                    >
                      <v-select
                          :items="answerOptions"
                          label="Select answer"
                          v-model="answer"
                      ></v-select>
                    </v-col>

                    <!--Answer Description-->
                    <v-col
                        cols="12"
                    >
                      <quill-editor
                          :options="{placeholder: 'Enter answer description'}"
                          v-model="answer_description"
                      ></quill-editor>
                    </v-col>

                    <!--Answer Photo-->
                    <v-col
                        cols="12"
                    >
                      <v-file-input
                          chips
                          label="Choose answer photo"
                          v-model="answer_photo"
                      ></v-file-input>
                    </v-col>


                    <v-btn
                        class="mr-4"
                        type="submit"
                        :disabled="invalid"
                        :loading="loading"
                    >
                      {{ editMode ? 'update' : 'submit' }}
                    </v-btn>
                    <v-btn @click="clear">
                      clear
                    </v-btn>
                  </v-row>
                </validation-observer>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <import-excel :mcq_sub_store_id='mcqSubStoreId' @updated="loadMcqs"></import-excel>
      <export-mcq :mcqs="mcqs" v-if="allowExport"></export-mcq>
      <v-btn @click="goToPrintPage" fab small color="primary" class="mx-2">
        <v-icon v-text="'mdi-cloud-print'"/>
      </v-btn>
    </v-col>
    <v-col cols="12" md="8">
      <show-mcq v-for="(mcq, index) in mcqs" :index="index" :mcq="mcq" :key="mcq.id" @updated="loadMcqs"
                @editMcq="edit">
      </show-mcq>
    </v-col>
  </v-row>
</template>
<script>
import {required} from 'vee-validate/dist/rules'
import ShowMcq from "../course/ShowMcq";
import axios from 'axios'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import ImportExcel from "../course/ImportExcel";
import ExportMcq from "../course/ExportMcq";

export default {
  components: {
    ExportMcq,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    ShowMcq,
    ImportExcel,
  },
  data: () => ({
    showEditButton: true,
    loading: false,
    mcqs: [],
    selectedMcq: null,
    editMode: false,
    dialog: false,
    allowExport: process.env.VUE_APP_EXPORT_MCQ_STORE === 'true',
    question: '',
    a: '',
    b: '',
    c: '',
    d: '',
    answer: '',
    answer_description: '',
    question_photo: null,
    answer_photo: null,
    snackbar: false,
    text: '',
    color: 'blue',

  }),
  computed: {
    mcqSubStoreId: function () {
      return this.$route.params.mcqSubStoreId;
    },
    form: function () {
      let formData = new FormData();
      formData.append('mcq_sub_store_id', this.$route.params.mcqSubStoreId);
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      if (this.question) {
        formData.append('question', this.question);
      }
      if (this.a) {
        formData.append('a', this.a);
      }
      if (this.b) {
        formData.append('b', this.b);
      }
      if (this.c) {
        formData.append('c', this.c);
      }
      if (this.d) {
        formData.append('d', this.d);
      }
      if (this.answer) {
        formData.append('answer', this.answer);
      }
      if (this.answer_description) {
        formData.append('answer_description', this.answer_description);
      }
      if (this.question_photo) {
        formData.append('question_photo', this.question_photo);
      }
      if (this.answer_photo) {
        formData.append('answer_photo', this.answer_photo);
      }
      return formData;
    },
    answerOptions: function () {
      let options = [this.a, this.b, this.c, this.d];
      return options.filter(function (el) {
        return el !== '';
      });
    }
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    createMcq() {
      this.editMode = false;
      this.selectedMcq = null;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
    },
    edit(mcq) {
      this.selectedMcq = mcq;
      this.dialog = true;
      this.editMode = true;
      this.question = mcq.question;
      this.a = mcq.a;
      this.b = mcq.b;
      this.c = mcq.c;
      this.d = mcq.d;
      this.answer = mcq.answer;
      this.answer_description = mcq.answer_description;
      this.question_photo = null;
      this.answer_photo = null;
    },
    submit() {
      const url = 'admin/mcqs';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New mcq created';
        this.loadMcqs();
        this.dialog = false;
      })
    },
    update() {
      const url = 'admin/mcqs/' + this.selectedMcq.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Mcq updated';
        this.loadMcqs();
        this.dialog = false;
      })
    },
    loadMcqs() {
      const url = 'admin/mcqs?mcq-sub-store=' + this.$route.params.mcqSubStoreId;
      axios.get(url).then((response) => {
        this.mcqs = response.data;
      })
    },
    clear() {
      this.editMode = false;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
      this.selectedMcq = null;
      this.$refs.observer.reset();
    },
    goToPrintPage(){
      const route = this.$router.resolve({name: 'PrintMcqs',params: {mcqSubStoreId: this.$route.params.mcqSubStoreId}}).href
      window.open(route,'_blank')
    }
  },
  mounted() {
    this.loadMcqs();
  }
}

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
</script>